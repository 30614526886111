import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Stack,
  MenuItem,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Loader from "components/Loader";
import { useLocation } from "react-router-dom";
import axios from "axios";
import styles from "./LoanReview.module.css";
import { toast } from "react-toastify";
import { IconDownload, IconEdit, IconTrash, IconX } from "@tabler/icons-react";

export default function LoanReview() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formVisible, setFormVisible] = useState(true);
  const route = useLocation().pathname.split("/").slice(1);
  const accountNo = route[route.length - 1];
  const [loanReviewHistory, setLoanReviewHistory] = useState([]);
  // form data handle

  const defaultValues = {
    borrowerName: "",
    accountNumber: accountNo,
    constitution: "",
    natureOfBusiness: "",
    limitFacilitiesSince: "",
    creditFacilities: [
      {
        type: "",
        margin: "",
        amount: "",
        interestRate: "",
        primeSecurity: "",
        value: "",
        insurance: "",
        presentOs: "",
        overdue: false,
        lastReviewRenewal: "",
      },
    ],
    personalGuarantees: [{ name: "" }],
    collateralSecurities: [
      {
        propertyLocation: "",
        value: "",
      },
    ],
    otherBankCreditFacilities: [
      {
        bankName: "",
        branch: "",
        typeOfAdvance: "",
        amountOfLimit: "",
        outstanding: "",
        remarks: "",
      },
    ],
    sisterConcernCreditFacilities: [
      {
        bankName: "",
        branch: "",
        typeOfAdvance: "",
        limitSanction: "",
        outstanding: "",
        remarks: "",
      },
    ],
    marketStatus: "",
    operationsExperience: "",
    lastInspectionDate: "",
    inspectingOfficer: "",
    inspectionObservations: "",
    years: [],
    financialParameters: [
      {
        name: "OPENING STOCK",
        values: { diff: 0 },
      },
      {
        name: "NET SALES",
        values: { diff: 0 },
      },
      {
        name: "NET PURCHASES",
        values: { diff: 0 },
      },
      {
        name: "LOANS FROM RELATIVES",
        values: { diff: 0 },
      },
      {
        name: "BANK LOANS",
        values: { diff: 0 },
      },
      {
        name: "CLOSING STOCKS",
        values: { diff: 0 },
      },
      {
        name: "GROSS PROFIT",
        values: { diff: 0 },
      },
      {
        name: "DEPRECIATION",
        values: { diff: 0 },
      },
      {
        name: "NET PROFIT",
        values: { diff: 0 },
      },
      {
        name: "CAPITAL",
        values: { diff: 0 },
      },
      {
        name: "ALL SECURED TERM LOAN",
        values: { diff: 0 },
      },
      {
        name: "CASH CREDIT/ OD",
        values: { diff: 0 },
      },
      {
        name: "UNSECURED LOANS",
        values: { diff: 0 },
      },
      {
        name: "SUNDRY CREDITORS",
        values: { diff: 0 },
      },
      {
        name: "OTHER CURRENT LIABILITIES",
        values: { diff: 0 },
      },
      {
        name: "OTHER CURRENT ASSETS",
        values: { diff: 0 },
      },
      {
        name: "FIXED ASSETS",
        values: { diff: 0 },
      },
      {
        name: "INVESTMENTS",
        values: { diff: 0 },
      },
      {
        name: "SUNDRY DEBTORS",
        values: { diff: 0 },
      },

      {
        name: "CASH/BANK BALANCE",
        values: { diff: 0 },
      },
    ],
    ratios: {
      currentRatio: {},
      debtEquityRatio: {},
      totalLiabilities: {},
      totalAssets: {},
    },
    auditIrregularities: "",
    remarks: "",
  };
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });

  // Field Arrays
  const {
    fields: creditFacilityFields,
    append: appendCreditFacility,
    remove: removeCreditFacility,
  } = useFieldArray({
    control,
    name: "creditFacilities",
  });

  const {
    fields: personalGuaranteeFields,
    append: appendPersonalGuarantee,
    remove: removePersonalGuarantee,
  } = useFieldArray({
    control,
    name: "personalGuarantees",
  });

  const {
    fields: collateralSecuritiesFields,
    append: appendCollateralSecurity,
    remove: removeCollateralSecurity,
  } = useFieldArray({
    control,
    name: "collateralSecurities",
  });

  const {
    fields: otherBankCreditFields,
    append: appendOtherBankCredit,
    remove: removeOtherBankCredit,
  } = useFieldArray({
    control,
    name: "otherBankCreditFacilities",
  });

  const {
    fields: sisterConcernFields,
    append: appendSisterConcern,
    remove: removeSisterConcern,
  } = useFieldArray({
    control,
    name: "sisterConcernCreditFacilities",
  });

  // financial parameters handlers
  const [parameterFields, years, ratios] = watch(["financialParameters", "years", "ratios"]);
  const {
    fields: yearFields,
    append: appendYear,
    remove: removeYear,
  } = useFieldArray({
    control,
    name: "years",
  });

  const removeFinancialParam = (index) => {
    const updatedValues = [...parameterFields];
    updatedValues.splice(index, 1);

    setValue("financialParameters", updatedValues);
  };
  const addFinancialParam = () => {
    let values = {};
    years.map((year) => {
      values[year.year] = "0";
    });
    const updatedValues = [...parameterFields, { name: "", values, new: true }];
    setValue("financialParameters", updatedValues);
  };

  const getValue = (name, year) => {
    const param = parameterFields.find((param) => param.name === name);
    return param && param.values[year] !== undefined ? param.values[year] : 0;
  };

  // calculators
  const calculateCurrentRatio = () => {
    yearFields.map((year) => {
      const sundryDebtors = parseFloat(getValue("SUNDRY DEBTORS", year.year));
      const investments = parseFloat(getValue("INVESTMENTS", year.year));
      const otherCurrentAssets = parseFloat(getValue("OTHER CURRENT ASSETS", year.year));
      const sundryCreditors = parseFloat(getValue("SUNDRY CREDITORS", year.year));
      const otherCurrentLiabilities = parseFloat(getValue("OTHER CURRENT LIABILITIES", year.year));
      const numerator = sundryDebtors + investments + otherCurrentAssets;
      const denominator = sundryCreditors + otherCurrentLiabilities;

      setValue(
        `ratios.currentRatio.${year.year}`,
        denominator !== 0 ? (numerator / denominator).toFixed(2) : "N/A"
      );
    });
  };
  const calculateDebtEquityRatio = () => {
    yearFields.map((year) => {
      const loansFromRelative = parseFloat(getValue("LOANS FROM RELATIVES", year.year));
      const bankLoans = parseFloat(getValue("BANK LOANS", year.year));
      const capital = parseFloat(getValue("CAPITAL", year.year));

      const numerator = loansFromRelative + bankLoans;

      const debtRatioValue = capital !== 0 ? (numerator / capital).toFixed(2) : "N/A";
      setValue(`ratios.debtEquityRatio.${year.year}`, debtRatioValue);
    });
  };
  const calculateTotalLiabilities = () => {
    yearFields.map((year) => {
      const capital = parseFloat(getValue("CAPITAL", year.year));
      const allSecuredTermLoan = parseFloat(getValue("ALL SECURED TERM LOAN", year.year));
      const caseCreditOD = parseFloat(getValue("CASH CREDIT/ OD", year.year));
      const unsecuredLoans = parseFloat(getValue("UNSECURED LOANS", year.year));
      const sundryCreditors = parseFloat(getValue("SUNDRY CREDITORS", year.year));
      const otherCurrentLiabilities = parseFloat(getValue("OTHER CURRENT LIABILITIES", year.year));

      const totalLiabilities =
        capital +
        allSecuredTermLoan +
        caseCreditOD +
        unsecuredLoans +
        sundryCreditors +
        otherCurrentLiabilities;
      setValue(`ratios.totalLiabilities.${year.year}`, totalLiabilities);
    });
  };
  const calculateTotalAsset = () => {
    yearFields.map((year) => {
      const fixedAssets = parseFloat(getValue("FIXED ASSETS", year.year));
      const investments = parseFloat(getValue("INVESTMENTS", year.year));
      const sundryDebtors = parseFloat(getValue("SUNDRY DEBTORS", year.year));
      const closingStocks = parseFloat(getValue("CLOSING STOCKS", year.year));
      const cashBankBalance = parseFloat(getValue("CASH/BANK BALANCE", year.year));

      const totalAssets =
        fixedAssets + investments + sundryDebtors + closingStocks + cashBankBalance;
      setValue(`ratios.totalAssets.${year.year}`, totalAssets);
    });
  };
  const calculateTotalAssets = () => {};

  const handleAddYear = (newYear) => {
    if (yearFields.length == 2) {
      toast.warn("Max year limit reached");
      return;
    }
    if (newYear.trim() === "" || yearFields.includes(newYear)) return;

    appendYear({ year: newYear });

    parameterFields.forEach((_, paramIndex) => {
      const currentValues = getValues(`financialParameters[${paramIndex}].values`);

      // Dynamically add the new year with an initial value of 0
      const updatedValues = { ...currentValues, [newYear]: "0", diff: 0 };

      setValue(`financialParameters[${paramIndex}].values`, updatedValues);
    });
  };

  const handleYearRemove = (yearIndex) => {
    const removedYear = yearFields[yearIndex];
    removeYear(yearIndex); // Remove the year globally
    parameterFields.forEach((_, paramIndex) => {
      const paramValues = getValues(`financialParameters[${paramIndex}].values`);
      delete paramValues[removedYear]; // Remove the year value from each parameter
      setValue(`financialParameters[${paramIndex}].values`, paramValues);
    });
    const rat = ratios;
    delete ratios.currentRatio[removedYear];
    delete ratios.debtEquityRatio[removedYear];
    setValue("ratios", rat);
  };
  // -----------------

  const onSubmit = async (data) => {
    const url = backendUrl + `/loan-review/create`;
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: data,
      });
      toast.success(response?.data?.message || "Submitted");
      setLoanReviewHistory((prev) => [...prev, response?.data]);
      reset(defaultValues);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  // lead data form
  const {
    handleSubmit: handleSubmit2,
    control: control2,
    formState: { isSubmitting: isSubmitting2 },
  } = useForm({
    defaultValues: {
      accountNo: accountNo,
      leadNo: "",
      type: "",
    },
  });
  const [yearsList, setYearsList] = useState([]);
  const [financialDataFetched, setFinancialDataFetched] = useState([]);
  const getLeadData = async (data) => {
    try {
      const url =
        backendUrl +
        `/loan-review/fetchData?acctNo=${data.accountNo}&lead_no=${data.leadNo}&type=${data.type}`;
      const response = await axios.get(url);
      setFormVisible(true);
      setYearsList(response?.data?.financial_details.year || []);
      setFinancialDataFetched(response?.data?.financial_details);
      reset(
        {
          ...defaultValues,
          borrowerName: response?.data?.customerDetails?.acctNm || "",
          personalGuarantees: response?.data?.guarantor_details || [{ name: "" }],
          natureOfBusiness: response?.data?.nature_of_business || "",
          limitFacilitiesSince: response?.data?.customerDetails?.opDate || "",
        },
        { keepDirty: true, keepTouched: true }
      );
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  const [fetchYearLoading, setFetchYearLoading] = useState(false);
  const fetchFinancialParams = () => {
    if (yearFields.length == 2) {
      toast.warn("Max year limit reached");
      return;
    }
    const year = document.getElementById("financialYear").value;
    if (yearFields.find((y) => y.year == year)) {
      toast.warn("Year is already in table");
      return;
    }
    setFetchYearLoading(true);
    const index = financialDataFetched?.year.findIndex((y) => y == year);
    appendYear({ year });

    // opening stocks
    const updatedOpeningStocks = {
      ...parameterFields[0].values,
      [year]: financialDataFetched?.opening_stock[index] || 0,
    };
    setValue(`financialParameters[${0}].values`, updatedOpeningStocks);

    // net sales
    const updatedNetSales = {
      ...parameterFields[1].values,
      [year]: financialDataFetched?.net_sales[index] || 0,
    };
    setValue(`financialParameters[${1}].values`, updatedNetSales);

    // NET PURCHASES
    const updatedNetPurchase = {
      ...parameterFields[2].values,
      [year]: financialDataFetched?.net_purchase[index] || 0,
    };
    setValue(`financialParameters[${2}].values`, updatedNetPurchase);

    // CLOSING STOCKS 5
    const updatedClosingStocks = {
      ...parameterFields[5].values,
      [year]: financialDataFetched?.closing_stock[index] || 0,
    };
    setValue(`financialParameters[${5}].values`, updatedClosingStocks);

    // GROSS PROFIT 6
    const updatedGrossProfit = {
      ...parameterFields[6].values,
      [year]: financialDataFetched?.gross_profit[index] || 0,
    };
    setValue(`financialParameters[${6}].values`, updatedGrossProfit);

    // DEPRECIATION 7
    const updatedDepreciation = {
      ...parameterFields[7].values,
      [year]: financialDataFetched?.depreciation[index] || 0,
    };
    setValue(`financialParameters[${7}].values`, updatedDepreciation);

    // NET PROFIT 8
    const updatedNetProfit = {
      ...parameterFields[8].values,
      [year]: financialDataFetched?.net_profit[index] || 0,
    };
    setValue(`financialParameters[${8}].values`, updatedNetProfit);

    // CAPITAL 9
    const updatedCapital = {
      ...parameterFields[9].values,
      [year]: financialDataFetched?.capital[index] || 0,
    };
    setValue(`financialParameters[${9}].values`, updatedCapital);

    // CASH CREDIT/ OD 11
    const updatedCashCreadit = {
      ...parameterFields[11].values,
      [year]: financialDataFetched?.cash_credit[index] || 0,
    };
    setValue(`financialParameters[${11}].values`, updatedCashCreadit);

    // UNSECURED LOANS 12
    const updatedUnsecuredLoans = {
      ...parameterFields[12].values,
      [year]: financialDataFetched?.unsecured_loans[index] || 0,
    };
    setValue(`financialParameters[${12}].values`, updatedUnsecuredLoans);

    // SUNDRY CREDITORS 13
    const updatedSundryCreditors = {
      ...parameterFields[13].values,
      [year]: financialDataFetched?.sundry_creditors[index] || 0,
    };
    setValue(`financialParameters[${13}].values`, updatedSundryCreditors);

    // OTHER CURRENT LIABILITIES 14
    const updatedOtherCurrentLiablities = {
      ...parameterFields[14].values,
      [year]: financialDataFetched?.other_current_liabilities[index] || 0,
    };
    setValue(`financialParameters[${14}].values`, updatedOtherCurrentLiablities);

    // ALL SECURED TERM LOAN 10
    const updatedSecuredTermLoans = {
      ...parameterFields[10].values,
      [year]: financialDataFetched?.all_secured_term_loan[index] || 0,
    };
    setValue(`financialParameters[${10}].values`, updatedSecuredTermLoans);

    // OTHER CURRENT ASSETS 15
    const updatedOtherCurrentAssets = {
      ...parameterFields[15].values,
      [year]: financialDataFetched?.other_current_assets[index] || 0,
    };
    setValue(`financialParameters[${15}].values`, updatedOtherCurrentAssets);

    // FIXED ASSETS 16
    const updatedFixedAssets = {
      ...parameterFields[16].values,
      [year]: financialDataFetched?.fixed_assets[index] || 0,
    };
    setValue(`financialParameters[${16}].values`, updatedFixedAssets);

    // INVESTMENTS 17
    const updatedInvestments = {
      ...parameterFields[17].values,
      [year]: financialDataFetched?.investments[index] || 0,
    };
    setValue(`financialParameters[${17}].values`, updatedInvestments);

    // SUNDRY DEBTORS 18
    const updatedSundryDebtors = {
      ...parameterFields[18].values,
      [year]: financialDataFetched?.sundry_debtors[index] || 0,
    };
    setValue(`financialParameters[${18}].values`, updatedSundryDebtors);

    // CASH/BANK BALANCE 19
    const updatedCashBankBalance = {
      ...parameterFields[19].values,
      [year]: financialDataFetched?.cash_and_bank_balance[index] || 0,
    };
    setValue(`financialParameters[${19}].values`, updatedCashBankBalance);

    calculateCurrentRatio(year);
    calculateDebtEquityRatio(year);
    setFetchYearLoading(false);
    // LOANS FROM RELATIVES 3
    // BANK LOANS 4
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
    // hide last border
  }));

  // loan review history
  const getLoanHistory = async () => {
    try {
      const url = backendUrl + `/loan-review/get?acctNo=${accountNo}`;
      const res = await axios.get(url);
      setLoanReviewHistory(res.data || []);
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };
  const deleteRecord = async (id) => {
    const url = backendUrl + `/loan-review/delete/${id}`;
    try {
      const res = await axios.delete(url);
      const updatedHistory = loanReviewHistory.filter((item) => item.id !== id);
      setLoanReviewHistory(updatedHistory);
      toast.success(res?.data?.message || "Record Deleted");
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  const editRecord = (data) => {
    reset(data);
  };
  const downloadExcel = (id) => {
    const url = backendUrl + `/loan-review/download/excel/${id}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    getLoanHistory();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isSubmitting ? (
        <Loader />
      ) : (
        <>
          <div className={styles.formContainer}>
            <Typography variant="h4" gutterBottom textAlign="center">
              Fetch Lead Data
            </Typography>
            <form onSubmit={handleSubmit2(getLeadData)}>
              <div className={styles.formGroup}>
                <Controller
                  name="accountNo"
                  control={control2}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Account Number"
                      fullWidth
                      disabled
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div className={styles.formGroup}>
                <Controller
                  name="type"
                  control={control2}
                  defaultValue=""
                  rules={{ required: "Type is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      id="type"
                      label="Type"
                      select
                      fullWidth
                      required
                      error={!!error}
                      helperText={error ? error.message : null}
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    >
                      <option value="">Select type...</option>
                      <option value="msme">MSME</option>
                      <option value="retail">Retail</option>
                    </TextField>
                  )}
                />
              </div>
              <div className={styles.formGroup}>
                <Controller
                  name="leadNo"
                  control={control2}
                  defaultValue=""
                  rules={{ required: "Lead Number is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Lead Number"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <Button
                className="ActionButtonsColor"
                disabled={isSubmitting2}
                variant="contained"
                type="submit"
                fullWidth
              >
                {isSubmitting2 ? <CircularProgress size={24} /> : "Search"}
              </Button>
            </form>
          </div>
          {formVisible ? (
            <>
              <div className={styles.shadow} />
              <div style={{ margin: "20px" }}>
                <Typography variant="h5" gutterBottom textAlign="center">
                  Loan Review History
                </Typography>
                <div className={styles.historyContainer}>
                  <TableContainer
                    sx={{
                      maxHeight: "400px",
                      border: "1px solid lightgray",
                      boxShadow: 0,
                      overflow: "auto",
                    }}
                  >
                    <Table sx={{ minWidth: "600px" }}>
                      <TableHead sx={{ display: "contents", userSelect: "none" }}>
                        <TableRow
                          className="NewTableHeader"
                          sx={{
                            color: "white !important",
                            position: "sticky",
                            top: 0,
                            zIndex: 999,
                            cursor: "pointer",
                          }}
                        >
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Account No
                          </TableCell>
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Borrower Name
                          </TableCell>
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Constitution
                          </TableCell>
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Remarks
                          </TableCell>
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Created Time
                          </TableCell>
                          <TableCell className="NewTableHeaderFontColor" align="left">
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loanReviewHistory?.length ? (
                          loanReviewHistory?.map((row) => (
                            <StyledTableRow key={row.id}>
                              <TableCell className="NewTableCells" align="left">
                                {row.accountNumber}
                              </TableCell>
                              <TableCell className="NewTableCells" align="left">
                                {row.borrowerName}
                              </TableCell>
                              <TableCell className="NewTableCells" align="left">
                                {row.constitution}
                              </TableCell>
                              <TableCell className="NewTableCells" align="left">
                                {row.remarks}
                              </TableCell>
                              <TableCell className="NewTableCells" align="left">
                                {row.createdTime}
                              </TableCell>
                              <TableCell className="NewTableCells" align="left">
                                <Button
                                  onClick={() => deleteRecord(row.id)}
                                  sx={{ color: "red" }}
                                  startIcon={<IconTrash />}
                                >
                                  Delete
                                </Button>
                                <Button onClick={() => editRecord(row)} startIcon={<IconEdit />}>
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => downloadExcel(row.id)}
                                  startIcon={<IconDownload />}
                                >
                                  Download Excel
                                </Button>
                              </TableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <StyledTableRow>
                            <TableCell className="NewTableCells" align="left" colSpan={6}>
                              No Data
                            </TableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <Typography variant="h5" gutterBottom textAlign="center">
                  Loan Review Form
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={3}>
                    {/* Borrower Information */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Borrower Information</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="borrowerName"
                        control={control}
                        rules={{ required: "Borrower Name is required" }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            label="Borrower Name"
                            fullWidth
                            required
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="accountNumber"
                        control={control}
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            label="Account Number"
                            fullWidth
                            disabled
                            error={!!error}
                            helperText={error?.message}
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="constitution"
                        control={control}
                        rules={{ required: "Constitution is required" }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            label="Constitution"
                            fullWidth
                            required
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="natureOfBusiness"
                        control={control}
                        rules={{ required: "Nature of Business is required" }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            label="Nature of Business"
                            fullWidth
                            required
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="limitFacilitiesSince"
                        control={control}
                        rules={{ required: "Limit Facilities Since is required" }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            label="Limit Facilities Since"
                            type="date"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            required
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>

                    {/* Credit Facilities */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Credit Facilities</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    {/* <Grid item xs={12}> */}
                    <Grid item xs={12}>
                      <TableContainer sx={{ border: "1px solid lightgray", boxShadow: 0 }}>
                        <Table>
                          <TableHead>
                            <TableRow className="NewTableHeader">
                              <TableCell className="NewTableHeaderFontColor">Type</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Margin</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Amount</TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Interest Rate (%)
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Prime Security
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Value</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Insurance</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Present OS</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Overdue</TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Last Review/Renewal
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {creditFacilityFields.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].type`}
                                    control={control}
                                    rules={{ required: "Type is required" }}
                                    render={({ field, fieldState: { error } }) => (
                                      <TextField
                                        {...field}
                                        //   fullWidth
                                        required
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].margin`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} type="number" />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].amount`}
                                    control={control}
                                    rules={{ required: "Amount is required" }}
                                    render={({ field, fieldState: { error } }) => (
                                      <TextField
                                        {...field}
                                        type="number"
                                        required
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].interestRate`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} type="number" />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].primeSecurity`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].value`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} type="number" />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].insurance`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} type="number" />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].presentOs`}
                                    control={control}
                                    render={({ field }) => <TextField {...field} type="number" />}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].overdue`}
                                    control={control}
                                    render={({ field }) => (
                                      <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label=""
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`creditFacilities[${index}].lastReviewRenewal`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        type="date"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    minWidth: "200px",
                                    display: index == 0 ? "none" : "",
                                    borderTop: index == 1 ? "1px solid lightgray" : "",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => removeCreditFacility(index)}
                                    color="secondary"
                                  >
                                    <Remove />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={12} sx={{ borderTop: "1px solid lightgray" }}>
                                <Button
                                  variant="contained"
                                  className="ActionButtonsColor"
                                  startIcon={<Add />}
                                  onClick={() =>
                                    appendCreditFacility({
                                      type: "",
                                      margin: "",
                                      amount: "",
                                      interestRate: "",
                                      primeSecurity: "",
                                      value: "",
                                      insurance: "",
                                      presentOs: "",
                                      overdue: false,
                                      lastReviewRenewal: "",
                                    })
                                  }
                                >
                                  Add Credit Facility
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* Personal Guarantees */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Personal Guarantees</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer sx={{ boxShadow: 0, border: "1px solid lightgray" }}>
                        <Table>
                          <TableHead>
                            <TableRow className="NewTableHeader">
                              <TableCell className="NewTableHeaderFontColor">
                                Guarantee Name
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {personalGuaranteeFields.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell align="left" sx={{ minWidth: "200px" }}>
                                  <Controller
                                    name={`personalGuarantees[${index}].name`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label={`Guarantee ${index + 1}`}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    display: index == 0 ? "none" : "",
                                    borderTop: index == 1 ? "1px solid lightgray" : "",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => removePersonalGuarantee(index)}
                                    color="secondary"
                                  >
                                    <Remove />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={2} sx={{ borderTop: "1px solid lightgray" }}>
                                <Button
                                  variant="contained"
                                  className="ActionButtonsColor"
                                  startIcon={<Add />}
                                  onClick={() => appendPersonalGuarantee({ name: "" })}
                                >
                                  Add Personal Guarantee
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* Collateral Securities */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Collateral Securities</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer sx={{ boxShadow: 0, border: "1px solid lightgray" }}>
                        <Table>
                          <TableHead>
                            <TableRow className="NewTableHeader">
                              <TableCell className="NewTableHeaderFontColor">
                                Property Location
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Value</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {collateralSecuritiesFields.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Controller
                                    name={`collateralSecurities[${index}].propertyLocation`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Property Location" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`collateralSecurities[${index}].value`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Value" type="number" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    display: index == 0 ? "none" : "",
                                    borderTop: index == 1 ? "1px solid lightgray" : "",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => removeCollateralSecurity(index)}
                                    color="secondary"
                                  >
                                    <Remove />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={3} sx={{ borderTop: "1px solid lightgray" }}>
                                <Button
                                  variant="contained"
                                  className="ActionButtonsColor"
                                  startIcon={<Add />}
                                  onClick={() =>
                                    appendCollateralSecurity({ propertyLocation: "", value: "" })
                                  }
                                >
                                  Add Collateral Security
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* Other Bank Credit Facilities */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Other Bank Credit Facilities</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer sx={{ boxShadow: 0, border: "1px solid lightgray" }}>
                        <Table>
                          <TableHead>
                            <TableRow className="NewTableHeader">
                              <TableCell className="NewTableHeaderFontColor">Bank Name</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Branch</TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Type of Advance
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Amount of Limit
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Outstanding</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Remarks</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {otherBankCreditFields.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].bankName`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Bank Name" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].branch`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Branch" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].typeOfAdvance`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Type of Advance" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].amountOfLimit`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label="Amount of Limit"
                                        type="number"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].outstanding`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label="Outstanding"
                                        type="number"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`otherBankCreditFacilities[${index}].remarks`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Remarks" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    display: index == 0 ? "none" : "",
                                    borderTop: index == 1 ? "1px solid lightgray" : "",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => removeOtherBankCredit(index)}
                                    color="secondary"
                                  >
                                    <Remove />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={7} sx={{ borderTop: "1px solid lightgray" }}>
                                <Button
                                  variant="contained"
                                  className="ActionButtonsColor"
                                  startIcon={<Add />}
                                  onClick={() =>
                                    appendOtherBankCredit({
                                      bankName: "",
                                      branch: "",
                                      typeOfAdvance: "",
                                      amountOfLimit: "",
                                      outstanding: "",
                                      remarks: "",
                                    })
                                  }
                                >
                                  Add Other Bank Credit Facility
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* Sister Concern Credit Facilities */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Sister Concern Credit Facilities</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer sx={{ boxShadow: 0, border: "1px solid lightgray" }}>
                        <Table>
                          <TableHead>
                            <TableRow className="NewTableHeader">
                              <TableCell className="NewTableHeaderFontColor">Bank Name</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Branch</TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Type of Advance
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">
                                Limit Sanction
                              </TableCell>
                              <TableCell className="NewTableHeaderFontColor">Outstanding</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Remarks</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sisterConcernFields.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].bankName`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Bank Name" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].branch`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Branch" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].typeOfAdvance`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Type of Advance" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].limitSanction`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label="Limit Sanction"
                                        type="number"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].outstanding`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label="Outstanding"
                                        type="number"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Controller
                                    name={`sisterConcernCreditFacilities[${index}].remarks`}
                                    control={control}
                                    render={({ field }) => (
                                      <TextField {...field} label="Remarks" fullWidth />
                                    )}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    display: index == 0 ? "none" : "",
                                    borderTop: index == 1 ? "1px solid lightgray" : "",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => removeSisterConcern(index)}
                                    color="secondary"
                                  >
                                    <Remove />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={7} sx={{ borderTop: "1px solid lightgray" }}>
                                <Button
                                  variant="contained"
                                  className="ActionButtonsColor"
                                  startIcon={<Add />}
                                  onClick={() =>
                                    appendSisterConcern({
                                      bankName: "",
                                      branch: "",
                                      typeOfAdvance: "",
                                      limitSanction: "",
                                      outstanding: "",
                                      remarks: "",
                                    })
                                  }
                                >
                                  Add Sister Concern Credit Facility
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    {/* Additional Information */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Additional Information</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="marketStatus"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Market Status"
                            multiline
                            rows={5}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="operationsExperience"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Operations Experience"
                            multiline
                            rows={5}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="lastInspectionDate"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Last Inspection Date"
                            type="date"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="inspectingOfficer"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Inspecting Officer" fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="inspectionObservations"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Inspection Observations"
                            fullWidth
                            multiline
                            rows={4}
                          />
                        )}
                      />
                    </Grid>

                    {/* Financial Parameters */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Financial Parameters</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12} mb={1}>
                      <Stack
                        justifyContent={"space-between"}
                        flexDirection={"row"}
                        gap={2}
                        flexWrap={"wrap"}
                      >
                        <Stack flexDirection={"row"} gap={1}>
                          <TextField
                            select
                            label="Select Year"
                            SelectProps={{
                              native: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="financialYear"
                            sx={{ minWidth: 250 }}
                            size="small"
                          >
                            {yearsList.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </TextField>
                          <Button
                            onClick={fetchFinancialParams}
                            variant="contained"
                            disabled={
                              !document.getElementById("financialYear")?.value || fetchYearLoading
                            }
                            className="ActionButtonsColor"
                            size="small"
                          >
                            {fetchYearLoading ? <CircularProgress size={22} /> : "Fetch"}
                          </Button>
                        </Stack>
                        <Stack flexDirection={"row"} gap={1}>
                          <TextField
                            sx={{ minWidth: 250 }}
                            label="New Year"
                            size="small"
                            id="new-year-input"
                          />
                          <Button
                            variant="contained"
                            className="ActionButtonsColor"
                            size="small"
                            startIcon={<Add />}
                            onClick={() => {
                              const newYear = document.getElementById("new-year-input").value;
                              handleAddYear(newYear);
                              document.getElementById("new-year-input").value = ""; // Clear input
                            }}
                          >
                            Add
                          </Button>
                        </Stack>
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <TableContainer sx={{ boxShadow: 1, border: "1px solid lightgray" }}>
                        <Table>
                          <TableHead>
                            <TableRow className="NewTableHeader">
                              <TableCell className="NewTableHeaderFontColor">Parameters</TableCell>
                              {yearFields.map((yearField, yearIndex) => (
                                <TableCell className="NewTableHeaderFontColor" key={yearIndex}>
                                  <Stack
                                    gap={1}
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <Typography variant="h6" sx={{ color: "white !important" }}>
                                      {yearField.year}
                                    </Typography>

                                    <Button
                                      variant="text"
                                      color="error"
                                      onClick={() => handleYearRemove(yearIndex)}
                                      size="small"
                                    >
                                      <IconX color="white" size={20} />
                                    </Button>
                                  </Stack>
                                </TableCell>
                              ))}
                              <TableCell className="NewTableHeaderFontColor">Difference</TableCell>
                              <TableCell className="NewTableHeaderFontColor">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {parameterFields?.map((param, paramIndex) => {
                              return (
                                <>
                                  <TableRow key={paramIndex}>
                                    <TableCell>
                                      <Controller
                                        name={`financialParameters[${paramIndex}].name`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            {...field}
                                            type="text"
                                            value={param.name}
                                            // label="Parameter Name"
                                            size="small"
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </TableCell>
                                    {yearFields.map((yearField, yearIndex) => (
                                      <>
                                        <TableCell key={yearIndex}>
                                          <Controller
                                            name={`financialParameters[${paramIndex}].values[${yearField.year}]`}
                                            control={control}
                                            onKeyDown={(e) => {
                                              if (e.key === "-" || e.key === "e" || e.key === "E") {
                                                e.preventDefault(); // Block negative sign and scientific notation
                                              }
                                            }}
                                            inputProps={{ min: 0 }}
                                            render={({ field, fieldState: { error } }) => (
                                              <TextField
                                                {...field}
                                                type="number"
                                                size="small"
                                                fullWidth
                                                onInput={(e) => {
                                                  const editedYear = yearField.year;
                                                  const editedValue = e.target.value
                                                    ? parseFloat(e.target.value)
                                                    : 0;

                                                  // Identify which year is being edited and which is the other
                                                  const currentYear = yearFields[0]?.year;
                                                  const otherYear = yearFields[1]?.year;

                                                  let currentYearValue = 0;
                                                  let otherYearValue = 0;

                                                  if (editedYear === currentYear) {
                                                    currentYearValue = editedValue;
                                                    otherYearValue = param.values[otherYear] || 0;
                                                  } else {
                                                    currentYearValue =
                                                      param.values[currentYear] || 0;
                                                    otherYearValue = editedValue;
                                                  }
                                                  const diff = currentYearValue - otherYearValue;
                                                  // Set the difference (currentYearValue - otherYearValue)
                                                  setValue(
                                                    `financialParameters[${paramIndex}].values.diff`,
                                                    diff
                                                  );
                                                  // Cache param name and check once for ratio calculations
                                                  // const paramName = param.name;

                                                  // const currentRatioParams = new Set([
                                                  //   "SUNDRY DEBTORS",
                                                  //   "INVESTMENTS",
                                                  //   "OTHER CURRENT ASSETS",
                                                  //   "SUNDRY CREDITORS",
                                                  //   "OTHER CURRENT LIABILITIES",
                                                  // ]);
                                                  // const debtRatioParams = new Set([
                                                  //   "BANK LOANS",
                                                  //   "LOANS FROM RELATIVES",
                                                  //   "CAPITAL",
                                                  // ]);

                                                  // // Perform calculations only if needed
                                                  // if (currentRatioParams.has(paramName)) {
                                                  //   calculateCurrentRatio(
                                                  //     editedYear,
                                                  //     e.target.value,
                                                  //     paramName
                                                  //   );
                                                  // } else if (debtRatioParams.has(paramName)) {
                                                  //   calculateDebtEquityRatio(
                                                  //     editedYear,
                                                  //     e.target.value,
                                                  //     paramName
                                                  //   );
                                                  // }
                                                }}
                                                error={!!error}
                                                helperText={error?.message}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                      </>
                                    ))}
                                    <TableCell>
                                      <Controller
                                        name={`financialParameters[${paramIndex}].values.diff`}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            disabled
                                            {...field}
                                            value={field.value || 0}
                                            type="number"
                                            size="small"
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {param?.new ? (
                                        <Button
                                          onClick={() => removeFinancialParam(paramIndex)}
                                          sx={{ color: "red" }}
                                          startIcon={<IconTrash />}
                                        >
                                          Remove
                                        </Button>
                                      ) : (
                                        "--"
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                            <TableRow>
                              <TableCell>
                                <Typography fontWeight={600} sx={{ color: "black" }} variant="h6">
                                  Current Ratio ( CA / CL ) :
                                </Typography>
                              </TableCell>
                              {yearFields.map((year) => (
                                <TableCell className="NewTableCells" key={year.year}>
                                  {ratios.currentRatio[year.year]
                                    ? ratios.currentRatio[year.year]
                                    : "N/A"}
                                </TableCell>
                              ))}
                              <TableCell colSpan={2} align="left">
                                {yearFields.length ? (
                                  <Button
                                    variant="contained"
                                    className="ActionButtonsColor"
                                    onClick={calculateCurrentRatio}
                                  >
                                    Calculate Current Ratios
                                  </Button>
                                ) : null}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography fontWeight={600} sx={{ color: "black" }} variant="h6">
                                  Debt Equity Ratio :
                                </Typography>
                              </TableCell>
                              {yearFields.map((year) => (
                                <TableCell className="NewTableCells" key={year.year}>
                                  {ratios.debtEquityRatio[year.year]
                                    ? ratios.debtEquityRatio[year.year]
                                    : "N/A"}
                                </TableCell>
                              ))}
                              <TableCell colSpan={2} align="left">
                                {yearFields.length ? (
                                  <Button
                                    variant="contained"
                                    className="ActionButtonsColor"
                                    onClick={calculateDebtEquityRatio}
                                  >
                                    Calculate Debt Ratios
                                  </Button>
                                ) : null}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography fontWeight={600} sx={{ color: "black" }} variant="h6">
                                  Total Liability:
                                </Typography>
                              </TableCell>
                              {yearFields.map((year) => (
                                <TableCell className="NewTableCells" key={year.year}>
                                  {ratios.totalLiabilities[year.year]
                                    ? ratios.totalLiabilities[year.year]
                                    : "N/A"}
                                </TableCell>
                              ))}
                              <TableCell colSpan={2} align="left">
                                {yearFields.length ? (
                                  <Button
                                    variant="contained"
                                    className="ActionButtonsColor"
                                    onClick={calculateTotalLiabilities}
                                  >
                                    Calculate Total Liabilities
                                  </Button>
                                ) : null}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography fontWeight={600} sx={{ color: "black" }} variant="h6">
                                  Total Assets:
                                </Typography>
                              </TableCell>
                              {yearFields.map((year) => (
                                <TableCell className="NewTableCells" key={year.year}>
                                  {ratios.totalAssets[year.year]
                                    ? ratios.totalAssets[year.year]
                                    : "N/A"}
                                </TableCell>
                              ))}
                              <TableCell colSpan={2} align="left">
                                {yearFields.length ? (
                                  <Button
                                    variant="contained"
                                    className="ActionButtonsColor"
                                    onClick={calculateTotalAsset}
                                  >
                                    Calculate Total Assets
                                  </Button>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        variant="contained"
                        className="ActionButtonsColor"
                        onClick={addFinancialParam}
                        sx={{ mt: "5px", display: "block", ml: "auto" }}
                      >
                        Add parameter
                      </Button>
                    </Grid>
                    {/* Audit Irregularities and Remarks */}
                    <Grid item xs={12} mt={4}>
                      <Typography variant="h6">Additional Remarks</Typography>
                      <Divider
                        sx={{ width: "100%", bgcolor: "black !important", height: "1.4px" }}
                        flexItem
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="auditIrregularities"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Audit Irregularities" fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="remarks"
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} label="Remarks" fullWidth multiline rows={4} />
                        )}
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        className="ActionButtonsColor"
                        color="primary"
                        fullWidth
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </>
          ) : null}
        </>
      )}
    </DashboardLayout>
  );
}
