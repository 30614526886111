import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "components/Loader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import styles from "./Notices.module.css"; // Importing module.css
import axios from "axios";
import { styled } from "@mui/material/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
  TextField,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconReport } from "@tabler/icons-react";
import moment from "moment";
import { toast } from "react-toastify";

const Notices = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    resetField,
  } = useForm();
  const branch_code = sessionStorage.getItem("branch_code");
  const [products, setProducts] = useState([]);
  const [noticedAccounts, setNoticedAccounts] = useState([]);
  const [notNoticedAccounts, setNotNoticedAccounts] = useState([]);

  // Watch 'type' field to enable/disable 'product' field
  const selectedType = watch("type");
  const formData = watch();

  const [code, setCode] = useState(null);
  const onSubmit = async (data) => {
    try {
      // Prepare query parameters
      const branch = data.branch || branch_code;
      setCode(branch);
      const url = `${backendUrl}/account/noticed?acctType=${encodeURIComponent(
        data.product
      )}&BranchCode=${encodeURIComponent(branch)}&fromDate=${encodeURIComponent(
        data.fromDate
      )}&toDate=${encodeURIComponent(data.toDate)}`;

      // Make the API call
      const res = await axios.get(url);

      // Handle API response
      const noticedAccounts = res.data.noticed || [];
      const notNoticedAccounts = res.data.notNoticed || [];

      if (noticedAccounts.length === 0 && notNoticedAccounts.length === 0) {
        toast.info("No search results found.");
      } else {
        toast.success("Data retrieved successfully.");
      }

      // Update state
      setNoticedAccounts(noticedAccounts);
      setNotNoticedAccounts(notNoticedAccounts);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const getProducts = async () => {
    const url = backendUrl + "/account/accountTypes";
    try {
      const res = await axios.get(url);
      setProducts(res.data);
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#edf6f9",
    },
  }));

  // Reset the product field when the type is changed
  useEffect(() => {
    resetField("product");
  }, [selectedType]);

  // view particular account
  const navigate = useNavigate();
  const handleView = (number, category) => {
    if (!category && category !== 0) {
      return;
    }
    let visitUrl = null;
    if (category === "01") {
      visitUrl = `/standard/summary/dashboard/${number}`;
    } else if (category === "SMA0" || category === "SMA1" || category === "SMA2") {
      visitUrl = `/sma/summary/dashboard/${number}`;
    } else {
      visitUrl = `/npa/summary/dashboard/${number}`;
    }
    navigate(visitUrl);
  };

  // reports
  const noticePendingUrl = `${backendUrl}/account/notice/pending-accounts/downloadPdf?acctType=${formData?.product}&BranchCode=${code}&fromDate=${formData?.fromDate}&toDate=${formData?.toDate}`;

  const noticedUrl = `${backendUrl}/account/notice/downloadPdf?acctType=${formData?.product}&BranchCode=${code}&fromDate=${formData?.fromDate}&toDate=${formData?.toDate}`;

  const [branches, setBranches] = useState([]);
  const getBranches = async () => {
    const url = backendUrl + "/account/getAllBranch";
    try {
      const res = await axios.get(url);
      setBranches(res.data);
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };
  useEffect(() => {
    if (!branch_code) {
      getBranches();
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isSubmitting ? <Loader /> : null}

      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {branch_code ? null : (
            <div className={styles.formGroup}>
              <TextField
                id="branch"
                label="Branch"
                select
                fullWidth
                disabled={!branches.length}
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                {...register("branch")}
              >
                <option value="">Select Branch...</option>
                {branches?.map((branch) => (
                  <option key={branch.code} value={branch.code}>
                    {branch.branch}
                  </option>
                ))}
              </TextField>
            </div>
          )}
          {/* Product Field */}
          <div className={styles.formGroup}>
            <TextField
              id="product"
              label="Product"
              select
              fullWidth
              variant="outlined"
              {...register("product", {
                required: selectedType ? "Product is required" : false,
              })}
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.product}
              helperText={errors?.product?.message}
              disabled={products.length === 0}
            >
              <option value="">Select product...</option>
              {products.map((option, index) => (
                <option key={index} value={option.code}>
                  {option.code} {option.name}
                </option>
              ))}
            </TextField>
          </div>

          {/* From Date Field */}
          <div className={styles.formGroup}>
            <TextField
              id="fromDate"
              label="From Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              {...register("fromDate", { required: "From Date is required" })}
              error={!!errors.fromDate}
              helperText={errors?.fromDate?.message}
            />
          </div>

          {/* To Date Field */}
          <div className={styles.formGroup}>
            <TextField
              id="toDate"
              label="To Date"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              {...register("toDate", { required: "To Date is required" })}
              error={!!errors.toDate}
              helperText={errors?.toDate?.message}
            />
          </div>

          {/* Submit Button */}
          <Button
            className="ActionButtonsColor"
            disabled={isSubmitting}
            variant="contained"
            type="submit"
            fullWidth
          >
            Search
          </Button>
        </form>
      </div>

      {/* Tables */}
      {noticedAccounts.length > 0 && (
        <div>
          <Typography variant="h5" sx={{ ml: 0.5, mb: 1 }}>
            Notice Generated Accounts:
          </Typography>
          <Stack alignItems="flex-end">
            <Button
              sx={{ width: "200px", mb: "5px" }}
              download
              href={noticedUrl}
              startIcon={<IconReport />}
            >
              Download Report
            </Button>
          </Stack>
          <TableContainer
            sx={{
              maxHeight: "400px",
              border: "1px solid lightgray",
              boxShadow: 0,
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account No
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account Holder
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Contact
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Outstand
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Notice Date
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {noticedAccounts.map((row) => (
                  <StyledTableRow key={row.acctCd}>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctCd || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctNm || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.contact || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.amount || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {moment(row.createDateTime).format("DD-MM-YYYY / hh:mm") || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      <Button onClick={() => handleView(row.acctCd, row.npaCd)}>View</Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {notNoticedAccounts.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <Typography variant="h5" sx={{ ml: 0.5, mb: 1 }}>
            Notice Not Generated Accounts:
          </Typography>
          <Stack alignItems="flex-end">
            <Button
              sx={{ width: "200px", mb: "5px" }}
              download
              href={noticePendingUrl}
              startIcon={<IconReport />}
            >
              Download Report
            </Button>
          </Stack>
          <TableContainer
            sx={{
              maxHeight: "400px",
              border: "1px solid lightgray",
              boxShadow: 0,
              overflow: "auto",
            }}
          >
            <Table sx={{ minWidth: "600px" }}>
              <TableHead sx={{ display: "contents", userSelect: "none" }}>
                <TableRow
                  className="NewTableHeader"
                  sx={{
                    color: "white !important",
                    position: "sticky",
                    top: 0,
                    zIndex: 999,
                    cursor: "pointer",
                  }}
                >
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account No
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Account Holder
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Contact
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Outstand
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    Place
                  </TableCell>
                  <TableCell className="NewTableHeaderFontColor" align="left">
                    View
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notNoticedAccounts.map((row) => (
                  <StyledTableRow key={row.acctCd}>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctCd || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.acctNm || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.contact || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.amount || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      {row.city || "--"}
                    </TableCell>
                    <TableCell className="NewTableCells" align="left">
                      <Button onClick={() => handleView(row.acctCd, row.npaCd)}>View</Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Notices;
