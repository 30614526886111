import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "components/Loader";
import { IconTrash } from "@tabler/icons-react";

function Events() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // React Hook Form setup
  const {
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      eventType: "Legal Notice",
      summary: "",
      createDateTime: "",
      eventImages: [],
    },
  });
  const [eventImages] = watch(["eventImages"]);
  // Fetch events history
  const getEventsHistory = async () => {
    try {
      const res = await axios.get(`${backendUrl}/Events/history/${id}`);
      setData(res.data || []);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEventsHistory();
  }, []);

  // Handle file upload
  const handleImageUpload = async (e) => {
    const files = e.target.files;
    const formData = new FormData();
    Array.from(files).forEach((file) => formData.append("files", file));

    try {
      const res = await axios.post(`${backendUrl}/Events/uploadImages`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const uploadedFiles = Array.from(files).map((file) => file.name);
      setValue("eventImages", [...eventImages, ...uploadedFiles]);
      toast.success("Files uploaded successfully");
    } catch (error) {
      toast.error("File upload failed");
    }
  };
  const handleDeleteFile = (index) => {
    const updatedFiles = eventImages.filter((_, i) => i !== index);
    setValue("eventImages", updatedFiles); // Update the form state
    toast.success("File removed successfully");
  };

  // Form submission
  const onSubmit = async (formData) => {
    try {
      await axios.post(`${backendUrl}/Events/create`, { ...formData, accountNumber: id });
      toast.success("Event recorded successfully");
      reset();
      getEventsHistory();
      setModalOpen(false);
    } catch (error) {
      toast.error("Failed to save event");
    }
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": { backgroundColor: "#edf6f9" },
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox sx={{ mt: 3 }}>
          <Typography sx={{ marginLeft: "3px", textAlign: "center" }} variant="h4">
            Events History
          </Typography>
          <TableContainer sx={{ maxHeight: "calc(100vh - 220px)", border: "1px solid lightgray" }}>
            <Table sx={{ minWidth: "600px" }}>
              <TableHead>
                <TableRow className="NewTableHeader" sx={{ color: "white", zIndex: 999 }}>
                  {["A/C No", "Event Type", "Event Date", "Summary", "Report"].map((header) => (
                    <TableCell
                      className="NewTableHeaderFontColor"
                      key={header}
                      sx={{ fontWeight: "700", color: "white" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length ? (
                  data.map((item, index) => (
                    <StyledTableRow key={index}>
                      <TableCell className="NewTableCells">{item.accountNumber || "---"}</TableCell>
                      <TableCell className="NewTableCells">{item.eventType || "---"}</TableCell>
                      <TableCell className="NewTableCells">
                        {item.createDateTime
                          ? moment(item.createDateTime).format("DD-MM-YYYY / hh:mm")
                          : "---"}
                      </TableCell>
                      <TableCell className="NewTableCells">{item.summary || "---"}</TableCell>
                      <TableCell className="NewTableCells">
                        {item.report ? (
                          <a href={item.report} download>
                            <Button>Download</Button>
                          </a>
                        ) : (
                          "---"
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack alignItems="flex-end" my={1}>
            <Button
              variant="contained"
              onClick={() => setModalOpen(true)}
              sx={{ color: "white", background: "#49a3f1" }}
              className="ActionButtonsColor"
            >
              Record New
            </Button>
          </Stack>
        </MDBox>
      )}

      {/* Dialog Form */}
      <Dialog
        open={modalOpen}
        onClose={() => (isSubmitting ? null : setModalOpen(false))}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Record New Event</DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "flex", flexDirection: "column", gap: 16, padding: "10px" }}
          >
            <Controller
              name="eventType"
              control={control}
              render={({ field }) => (
                <TextField
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  select
                  label="Event Type"
                  fullWidth
                  {...field}
                  size="medium"
                >
                  {["Legal Notice", "Suit File", "Hearing", "Decree"].map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="createDateTime"
              control={control}
              rules={{
                validate: (value) =>
                  moment(value).isSameOrBefore(moment()) || "Date cannot be in the future",
              }}
              render={({ field }) => (
                <TextField
                  type="date"
                  label="Event Date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  {...field}
                  inputProps={{
                    max: new Date().toISOString().slice(0, 16), // Current date and time
                  }}
                />
              )}
            />

            <Controller
              name="summary"
              control={control}
              render={({ field }) => (
                <TextField multiline rows={4} label="Summary" fullWidth {...field} />
              )}
            />

            <TextField
              label="Upload Files"
              type="file"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                multiple: true,
                accept: "image/*",
              }}
              onChange={(e) => handleImageUpload(e)}
              fullWidth
            />

            <TableContainer sx={{ border: "1px solid lightgray" }}>
              <Table>
                <TableHead>
                  <TableRow className="NewTableHeader" sx={{ zIndex: 999 }}>
                    <TableCell className="NewTableHeaderFontColor">Sr No</TableCell>
                    <TableCell className="NewTableHeaderFontColor">File Name</TableCell>
                    <TableCell className="NewTableHeaderFontColor">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventImages?.length ? (
                    eventImages?.map((image, index) => (
                      <TableRow key={index}>
                        <TableCell className="NewTableCells">{index + 1}</TableCell>
                        <TableCell className="NewTableCells">{image}</TableCell>
                        <TableCell className="NewTableCells">
                          <Button startIcon={<IconTrash />} onClick={() => handleDeleteFile(index)}>
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>No files upload</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <DialogActions>
              <Button
                disabled={isSubmitting}
                variant="contained"
                type="submit"
                className="ActionButtonsColor"
              >
                Submit
              </Button>
              <Button disabled={isSubmitting} onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}

export default Events;
